.multi-select-dropdown {
  position: relative;
}

.dropdown-header {
  cursor: pointer;
  padding: 9px 12px !important;
  border: 1px solid #cccccc97;
  border-radius: 5px;
  display: flex !important;
}

.dropdown-content {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdown-arrow {
  margin-left: 10px;
  display: inline-block;
  vertical-align: middle;
}

.dropdown-options {
  position: absolute;
  top: 41px;
  left: 0;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 7px;
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  font-size: 14px;
}

.dropdown-option {
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #cccccc8f;
}

.dropdown-option.selected {
  background-color: #f0f0f0;
}
