.crew_form .form-control:focus {
  box-shadow: none;
  border-color: var(--border);
}
.crew_form input,
.btn {
  box-shadow: none;
  color: var(--crew-name);
  font-weight: 400;
}
.crew_form .form-control {
  padding: 8px 0px 8px 8px;
}
.crew_form label {
  margin-bottom: 4px;
}
.crew_form .form-group {
  margin-top: 12px;
}
.modal_class .modal-title {
  font-size: 20px;
  font-weight: 700;
}
.modal_class .modal-header {
  border-bottom: none;
}
.modal_class .modal-footer {
  border-top: none;
}
.border_primary {
  border-color: var(--primary);
  color: var(--primary);
}
.modal_class .modal-dialog {
  max-width: 40rem;
}

.cursor_pointer {
  cursor: pointer;
}
.crew-item {
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.crew-item.deleting {
  transform: translateX(-100%);
  opacity: 0;
}
.crew-item li {
  transition: transform 0.8s ease-out, opacity 0.8s ease-out;
}

.custom-checkbox [type='checkbox'] {
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 1em;
  height: 1em;
  border: 1px solid;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  padding: 0;
}
.custom-checkbox [type='checkbox']::before {
  content: '';
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.custom-checkbox [type='checkbox']:checked::before {
  transform: scale(1);
  width: 0.65em;
  height: 0.65em;
}

.list {
  height: calc(100vh - 280px) !important;
}

.password-icon-crew {
  position: absolute;
  top: 40px;
  right: 10px;
  font-size: 18px;
}

.passwordValidation {
  position: absolute;
}
