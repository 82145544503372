@import '../styles/bootstrapBreakpoint.scss';
.inputs input,
.btn {
  width: 100%;
  padding: 14px 40px 6px 12px;
  border: none;
  border-radius: 4px;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  border: 1px solid var(--border);
  box-shadow: none;
  border-radius: 5px;
  font-size: 15px !important;
}
.search-icon {
  position: absolute;
  transform: translateY(72%);
  font-size: 16px;
  right: 236px;
}

.inputs input {
  color: black;
}

.inputs input::placeholder {
  font-size: 14px;
  color: var(--crew-name);
  opacity: 80%;
}

@include media-breakpoint-up(md) {
  .inputs {
    width: 100%;
  }
  .inputs input,
  .btn {
    padding: 14px 12px 6px 12px;
  }
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
  .inputs {
    width: auto;
  }
  .inputs input,
  .btn {
    padding: 14px 40px 6px 12px;
    width: 280px;
  }
  .search-icon {
    right: 8px;
  }
}
