.userprofile.active {
  background-color: var(--primary);
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  color: var(--white);
}
.userprofile {
  padding: 10px 14px;
}

.child-links a {
  color: var(--tertiary);
  text-decoration: none;
}

.adminName {
  color: var(--tertiary);
  font-weight: 700;
}

.adminRole {
  margin-left: 35px;
  margin-top: -7px;
}

.border-right {
  border-right: 1px solid var(--border);
}
.navbar-toggler {
  color: var(--white) !important;
}

.log_out {
  cursor: pointer;
  color: var(--tertiary);
}

.sidebar_w .width-20 {
  width: 20%;
}

.sidebar_w .width-80 {
  width: 80%;
}

.header_scroll {
  position: fixed;
  z-index: 1;
  left: 0px;
  right: 0px;
}
.z_index_3 {
  z-index: 3;
}
.header_margin {
  margin-bottom: 102px;
}

.backJobNavigation {
  width: fit-content;
}
