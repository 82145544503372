@import '../styles/bootstrapBreakpoint.scss';
.text_completed {
  color: var(--blue);
  font-weight: 700;
}

.text_success {
  color: var(--success);
  font-weight: 700;
}

.completed-styles {
  background-color: #e0f2f1;
  border: 1px solid var(--blue);
}

.success-styles {
  background-color: #dff0d8;
  border: 1px solid var(--success);
}

.edit_job input,
.btn {
  box-shadow: none;
}

.crew_icon svg {
  display: none;
}
.search_icon svg {
  right: auto;
  margin-left: -24px;
}
.check_box input,
.btn {
  box-shadow: none;
  width: auto;
  font-weight: 600;
}
.check_box input,
.btn::placeholder {
  font-size: 15px;
  margin-right: 12px;
}
.hr-line {
  opacity: 1;
  display: flex;
  justify-content: center;
  margin-left: -13px;
  align-items: center;
  border-color: #d2d2d2;
}
.post_input input,
.btn {
  box-shadow: none;
  padding-right: 140px;
}
.file_upload_icon {
  color: #acacac;
  border: 1px solid #acacac;
  background: none;
}
.notes_btn {
  margin-top: -48px;
  right: 25px;
}
ul {
  list-style-type: none;
}
.disabled-button {
  opacity: 0.5;
}
.edit-message-container {
  margin-left: -12px;
  margin-right: -9px;
}
.textarea-wrapper {
  position: relative;
  padding-bottom: 45px;
}

.text_area .form-control {
  resize: none;
  overflow-y: auto;
  font-size: 14px;
}

.button-container {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.button-container .btn-secondary {
  background: transparent !important;
  color: #1c1c1c !important;
  border: 1px solid #acacac !important;
}

.check_box [type='checkbox'] {
  appearance: none;
  background-color: #fff;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  padding: 0;
}
.check_box [type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: CanvasText;
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
.check_box [type='checkbox']:checked::before {
  transform: scale(1);
}
.form-control:focus {
  box-shadow: none !important;
}
.validate_modal .border_primary {
  border-color: var(--primary);
  background-color: var(--primary);
}
.validate_modal .border_primary:hover {
  border-color: var(--primary);
  background-color: var(--primary);
}
.validate_modal .submit {
  background-color: var(--primary);
}
.validate_modal .submit:hover {
  background-color: var(--primary);
}
.hide-close-button .btn-close {
  display: none;
}
.hide-close-button .modal-title,
.modal-body {
  font-size: 18px;
  font-weight: 700;
}
.hide-close-button .modal-header,
.modal-footer {
  border-bottom: 0;
}
.hide-close-button .modal-footer {
  border-top: 0;
}
.hide-close-button .modal-content {
  width: fit-content;
  margin: auto;
  padding-left: 12px;
  padding-right: 12px;
}
.notes_p {
  display: block;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-align: center;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
}
.disabled-text {
  color: rgb(171, 171, 171);
}
.edit_job input,
.btn {
  accent-color: black;
}
.loader .spinner_loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader_btn .spinner_loader .spinner-border {
  width: 1.6rem !important;
  height: 1.6rem !important;
  border-width: 0.2rem !important;
}
.loader_btn .spinner_loader .load_title {
  display: none;
}
.jobList {
  height: calc(100vh - 550px);
}
.notesList {
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 270px);

  &.with-preview {
    height: calc(100vh - 400px);
  }
}
.file-preview-wrapper {
  position: relative;
  margin-bottom: 10px;
}
.jobEdit {
  margin-top: 5px;
}

.addJobBtn {
  padding-top: 6.5px;
  padding-bottom: 6.5px;
}

.jobLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.job-item {
  transition: transform 5s ease-out, opacity 5s ease-out;
}

.job-item.deleting {
  transform: translateX(-100%);
  opacity: 0;
}

.job-item li {
  transition: transform 8s ease-out, opacity 8s ease-out;
}

.postNoteBtn {
  width: 95px;
}

.editNoteSave {
  background-color: var(--primary) !important;
  color: #fff !important;
  width: 82.3px !important;
}

@include media-breakpoint-up(md) {
  .notes_btn {
    right: 46px;
  }
}
@include media-breakpoint-up(lg) {
}
@include media-breakpoint-up(xl) {
  .notes_btn {
    margin-top: -48px;
    right: 10px;
  }
}
