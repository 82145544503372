.imagePreviewModal {
  .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .modal-content {
    height: 100%;
  }

  .modal-body {
    height: calc(100vh - 160px); // Adjusted to account for footer
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    &.large-image {
      align-items: flex-start;
    }
  }

  .imagePreviewModal__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .modal-footer {
    justify-content: center;
    padding: 10px;
  }

  .image-controls {
    display: flex;
    gap: 10px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #f8f9fa;
      border: 1px solid #dee2e6;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #e9ecef;
      }
    }

    .downloadFile {
      width: 20px;
      height: 20px;
    }
  }
}
