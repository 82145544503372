$primary: #ff6c00;
$secondary: #000000;
$tertiary: #757575;
$success: #17981c;
$danger: #d51919;
$blue: #205fbc;
$white: #ffff;
$placeholder: #888888;
$body: #f3f3f3;
$border: #7a7a7a4d;
$edit: #162574;
$crew-name: #202224;
$crew-list-bg: #fdf6f2;
$date-color: #7e7e7e;
$pagination: #e1dfdf;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --tertiary: #{$tertiary};
  --white: #{$white};
  --placeholder: #{$placeholder};
  --body: #{$body};
  --border: #{$border};
  --edit: #{$edit};
  --crew-name: #{$crew-name};
  --crew-list-bg: #{$crew-list-bg};
  --success: #{$success};
  --blue: #{$blue};
  --date-color: #{$date-color};
  --danger: #{$danger};
  --pagination: #{$pagination};
}

body::-webkit-scrollbar {
  display: none;
}
.text_primary {
  color: var(--primary);
}
.text_secondary {
  color: var(--primary);
}
.bg_primary {
  background-color: var(--primary);
}
.edit_icon_color {
  color: var(--edit);
}
.bg_crew_list {
  background-color: var(--crew-list-bg);
}
.crew_name_color {
  color: var(--crew-name);
  opacity: 80%;
  font-weight: 700;
}
.crew_time_color {
  color: var(--crew-name);
  font-weight: 700;
}
.date-color {
  color: var(--date-color);
}
.text_success {
  color: var(--success);
}
.text_blue {
  color: var(--blue);
}
.border_color {
  color: var(--border);
}
.text_tertiary {
  background-color: var(--tertiary);
}
.text_danger {
  color: var(--danger);
}
* {
  font-family: 'Nunito', sans-serif;
}
body {
  background-color: var(--body) !important;
}
$mds-font-sizes: (
  100: 100px,
  80: 80px,
  75: 75px,
  70: 70px,
  64: 64px,
  60: 60px,
  58: 58px,
  56: 56px,
  54: 54px,
  50: 50px,
  48: 48px,
  46: 46px,
  42: 42px,
  40: 40px,
  38: 38px,
  36: 36px,
  34: 34px,
  32: 32px,
  30: 30px,
  28: 28px,
  26: 26px,
  24: 24px,
  22: 22px,
  20: 20px,
  18: 18px,
  16: 16px,
  14: 14px,
  15: 15px,
  13: 13px,
  12: 12px,
  10: 10px,
  9: 9px,
  8: 8px,
  4: 4px,
);

@import 'bootstrap/scss/bootstrap';
// Generate base font size classes
@each $size, $value in $mds-font-sizes {
  .font-size-#{$size} {
    font-size: $value;
    /* Add any additional styles you want for the class */
  }
}
@each $breakpoint, $min-width in $grid-breakpoints {
  $breakpoint-abbreviation: str-replace($breakpoint, 'min-', '');
  @each $size, $value in $mds-font-sizes {
    .font-size-#{$breakpoint-abbreviation}-#{$size} {
      @media (min-width: $min-width) {
        // @extend .fw-bold; // Example: Extend Bootstrap's .fw-bold class
        font-size: $value;
        /* Add any additional styles for the responsive class */
      }
    }
  }
}

@each $breakpoint, $min-width in $grid-breakpoints {
  $breakpoint-abbreviation: str-replace($breakpoint, 'min-', '');
  @each $size, $value in $mds-font-sizes {
    .font-size-#{$breakpoint-abbreviation}-#{$size} {
      @media (min-width: $min-width) {
        // @extend .fw-bold; // Example: Extend Bootstrap's .fw-bold class
        font-size: $value;
        /* Add any additional styles for the responsive class */
      }
    }
  }
}

// generate classess for width from (1 -100) in %
@for $i from 1 through 600 {
  .width-rev-#{$i} {
    width: unquote('#{$i}%');
    /* Add any additional styles you want for the class */
  }
}

// Generate responsive variations for each breakpoint
@each $breakpoint, $min-width in $grid-breakpoints {
  $breakpoint-abbreviation: str-replace($breakpoint, 'min-', '');
  @for $i from 1 through 600 {
    .width-rev-#{$breakpoint-abbreviation}-#{$i} {
      @media (min-width: $min-width) {
        // @extend .fw-bold; // Example: Extend Bootstrap's .fw-bold class
        width: unquote('#{$i}%');
        /* Add any additional styles for the responsive class */
      }
    }
  }
}

// generate classess for width from (1 -100) in px
@for $i from 1 through 700 {
  .width-rev-px-#{$i} {
    width: $i + px;
    /* Add any additional styles you want for the class */
  }
}

// Generate responsive variations for each breakpoint
@each $breakpoint, $min-width in $grid-breakpoints {
  $breakpoint-abbreviation: str-replace($breakpoint, 'min-', '');
  @for $i from 1 through 700 {
    .width-rev-px-#{$breakpoint-abbreviation}-#{$i} {
      @media (min-width: $min-width) {
        // @extend .fw-bold; // Example: Extend Bootstrap's .fw-bold class
        width: $i + px;
        /* Add any additional styles for the responsive class */
      }
    }
  }
}

// generate classess for height from (1 -100) in px
@for $i from 1 through 700 {
  .height-rev-px-#{$i} {
    height: $i + px;
    /* Add any additional styles you want for the class */
  }
}

// Generate responsive variations for each breakpoint
@each $breakpoint, $min-width in $grid-breakpoints {
  $breakpoint-abbreviation: str-replace($breakpoint, 'min-', '');
  @for $i from 1 through 700 {
    .height-rev-px-#{$breakpoint-abbreviation}-#{$i} {
      @media (min-width: $min-width) {
        // @extend .fw-bold; // Example: Extend Bootstrap's .fw-bold class
        height: $i + px;
        /* Add any additional styles for the responsive class */
      }
    }
  }
}

.border_box {
  border: 1px solid var(--border);
  height: calc(100vh - 50px);
  background-color: var(--white);
}
.job_border_box {
  border: 1px solid var(--border);
  height: calc(100vh - 85px);
  background-color: var(--white);
}
.hr_line {
  opacity: 0.1;
  margin-left: -30px;
}
.mt-lg {
  margin-top: 26px;
}

.dropdowns .btn:first-child:active {
  border-radius: 1px;
  border-color: var(--border);
}

.calendar {
  display: flex;
  flex-direction: column;
  width: 350px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: sans-serif;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.calendar-header button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.calendar-title {
  font-weight: bold;
}

.chevron {
  font-size: 16px;
}

.chevron.left {
  transform: rotate(180deg);
}

.calendar-body {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}

.calendar-week {
  width: calc(100% / 7);
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
}

.day {
  width: calc(100% / 7);
  text-align: center;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.day.selected {
  background-color: #ddd;
}

.selected-date-range {
  padding: 10px;
  font-weight: bold;
}

.add-date-button {
  border: none;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  text-align: center;
}

#scrollstyle::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#scrollstyle::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}

#scrollstyle::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #cccccc;
}
.scroll_bar {
  overflow-y: auto;
  overflow-x: hidden;
}
.login_loader .spinner_loader .load_title {
  display: none;
}

.login_loader .spinner_loader .spinner-border {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-width: 0.2rem !important;
}

.colorLoader .spinner-border {
  color: #ff6c00 !important;
}

.pagination {
  position: absolute;
  right: 10px;
  bottom: 20px;
}

.page-item:first-child .page-link:focus,
.page-item:last-child .page-link:focus {
  color: black !important;
  box-shadow: none !important;
}

.pagination_list .page-link {
  margin-top: 20px;
  color: var(--secondary);
}
.pagination_list .active > .page-link,
.page-link.active {
  background-color: var(--pagination);
  color: var(--secondary);
  border-radius: 4px;
}
.pagination_list .page-link:hover {
  color: var(--secondary);
}
.min_height {
  min-height: 42rem;
}

.link {
  text-decoration: none;
  color: var(--white);
}

.btn-secondary {
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
  background-color: #fff !important;
}

.confirmBtn {
  width: 94.88px !important;
}

.addBtn {
  min-width: fit-content;
}

.search svg {
  right: 8px;
}

.cursor {
  cursor: pointer;
}

.btn-reset {
  background: none;
  border: none;
  outline: none;
}
