.report_ul {
  font-weight: 700;
  padding: 0;
}

.dropdown {
  padding-left: 2rem !important;
}

.table .text_success {
  color: var(--success);
  font-weight: 700;
}

.table .success-style {
  background-color: #dff0d8;
  outline: auto;
  border-radius: 28px;
  padding: 0px 15px;
  font-weight: 700;
}

.table .danger-style {
  color: var(--danger);
  background: #b9251133;
  outline: auto;
  border-radius: 28px;
  padding: 0px 15px;
  font-weight: 700;
}

.w_fit {
  width: fit-content;
}

.table tbody {
  padding-top: 10px !important;
}

.total-hours,
.overtime {
  font-weight: bold;
  text-align: center;
}

.table th:first-child {
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.table th:last-child {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.table th:nth-child(1),
.table td:nth-child(1) {
  padding-left: 25px;
}

// MARK: tbody scroller

.table-container {
  height: calc(100vh - 220px); /* Adjust this height as needed */
  overflow-y: auto;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.thead,
.thead th {
  position: sticky;
  top: 0;
  z-index: 2; /* Ensure it stays above tbody content */
}

// MARK: scroller end

.table-container td {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.table td,
.table > thead > tr > th {
  border: none;
  font-weight: 600;
}
.table > thead > tr {
  border: none;
}
.thead_bg,
.table > thead > tr {
  background-color: var(--crew-list-bg) !important;
}

.table_row td {
  color: var(--crew-name) !important;
}

.table-header th {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.hr_report {
  opacity: 0.1;
}

.datePicker {
  z-index: 4;
}

.react-datepicker__close-icon {
  display: none;
}

.react-datepicker__input-container input,
.btn {
  box-shadow: none;
}

.border_date {
  border: 1px solid var(--border);
  width: fit-content;
  margin-left: auto;
}

.calendar-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px; /* Adjust as needed */
  cursor: pointer;
}
.table_row > thead > tr > th {
  background-color: var(--crew-list-bg);
  padding: 14px 0px;
}
.table_row > thead > tr {
  border-radius: 7px !important;
}
.head_margin tr th {
  border: none !important;
  color: var(--crew-name);
}

select {
  outline: none;
}

.padding_refresh {
  padding-top: 11px;
  padding-bottom: 11px;
}

.place_holder,
.btn::placeholder {
  color: var(--secondary);
}

.position-relative:focus-visible {
  outline: none;
}

.position-relative input:focus-visible {
  outline: none;
}

.mt-10 {
  margin-top: 10px;
}

.react-datepicker__input-container > input {
  pointer-events: none;
}

.weekChangeBtn {
  margin-top: 3px;
}

.list {
  height: calc(100vh - 350px);
}

// MARK: Breakdown by job

.hr_job {
  opacity: 0.1;
}

.total_job td {
  background-color: #f4f4f4;
  color: #743202 !important;
  font-weight: 700;
  border-top: 1px solid #76767648;
}

.total_hour td {
  color: #aa500e !important;
  font-weight: 700;
}

.scrollable-tbody thead th:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  padding-left: 25px;
}

.scrollable-tbody thead th:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.scrollable-tbody {
  height: calc(100vh - 220px);
  overflow: hidden;
  scrollbar-gutter: stable;
}

.scrollable-tbody:hover {
  overflow: auto;
}

.scrollable-tbody table {
  width: 100%;
}

.scrollable-tbody th {
  position: sticky;
  top: 0;
  background-color: #ffede0;
}

.scrollable-tbody th:nth-child(1) {
  background-color: var(--crew-list-bg);
  z-index: 3;
  width: 200px;
  min-width: 200px !important;
}

.scrollable-tbody th.job-column {
  min-width: 150px;
}

.scrollable-tbody td {
  padding: 15px 10px;
}

.scrollable-tbody td:nth-child(1) {
  background-color: white;
  padding-left: 25px !important;
  opacity: 1;
}

.scrollable-tbody th:nth-child(1),
.scrollable-tbody td:nth-child(1) {
  position: sticky;
  left: 0;
  padding-left: 10px;
  box-shadow: -5px 0 5px -3px rgba(0, 0, 0, 0.2) inset;
}

.timerBorder {
  border-bottom: 1px solid rgba(118, 118, 118, 0.4);
}

.table-container .timerBorder {
  border-bottom: 1px solid rgba(118, 118, 118, 0.4);
}
