.title {
  font-weight: 700;
  font-size: 1.125rem;
  margin: 0;
}

.subtitle {
  color: #6d6d6d;
  font-size: 0.875rem;
  margin: 0;
  font-weight: 500;
}

.upload-box {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  border: 2px dashed var(--primary) !important; /* Bootstrap warning color */
  text-align: center;
  margin: 0 auto;
}

.upload-icon,
.upload-button {
  color: var(--primary); /* Adjust the color to match your design */
}

.upload-button {
  cursor: pointer;
}

.upload-text span {
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
}

.fileSize_limit {
  color: #6d6d6d;
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
}

.fileSupport_type {
  font-size: 0.875rem;
  font-weight: 500;
  margin: 0;
  color: #6d6d6d;
  text-align: left;
  margin-left: 35px;
}

.fileUpload_modal .modal-header {
  border-bottom: 0;
  padding-bottom: 0;
}

.fileUpload_modal .btn-close {
  position: absolute;
  top: 15px;
  right: 15px;
}

.selected-files {
  max-height: 100px;
  overflow-y: auto;
  background: #f6f6f6;
}

.note-attachments {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
}

.filePreview {
  width: 20px;
  height: 20px;
}

.filePreview__download {
  width: 18px;
  height: 18px;
}

.fileName {
  color: var(--primary);
}

.removeFileIcon {
  cursor: pointer;
}
