.bg_login {
  background-image: url('../../assets/images/bg-login.svg');
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  border-top-right-radius: 24px;
  border-bottom-right-radius: 24px;
}
input,
.btn {
  width: 100%;
  padding: 14px 0px 14px 50px;
  border: none;
  border-radius: 4px;
  opacity: 0.85;
  display: inline-block;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
  box-shadow: 0px 2px 8px 0px #00000026;
}
input::placeholder {
  color: var(--placeholder);
}
input {
  color: var(--placeholder);
}
.login_btn {
  background-color: var(--primary);
  border: none;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 700;
  color: var(--white);
}
.password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.password-icon {
  position: absolute;
  top: 16px;
  right: 10px;
  font-size: 18px;
}
.credential {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.credential-icon {
  position: absolute;
  top: 17px;
  left: 26px;
  width: 18px;
  height: 18px;
}

.errors {
  bottom: -25px;
}

.loginInput {
  padding-top: 15px;
}

@media (min-width: 1080px) {
  .form-max-width {
    max-width: 500px; /* Set the desired max-width */
    width: 50%;
  }
}
